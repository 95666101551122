import React, { Component } from 'react'
import classNames from 'classnames'
import PlayButton from 'src/components/svg/PlayButton'
import SocialShare from 'src/components/SocialShare'
import { PodcastContext } from 'src/components/PodcastLayout'
import Img from 'gatsby-image'
import styles from './Preview.module.scss'
import PropTypes from 'prop-types'
import { getBaseUrl } from 'src/utils'

class Preview extends Component {
  handleClickPlay = () => {
    this.setState({ isPlaying: !this.state.isPlaying })
  }

  render() {
    const {
      className,
      locale,
      node: {
        cover,
        duration,
        guest,
        podcast: { url: podcastFile },
        slug,
        title,
      },
    } = this.props

    return (
      <PodcastContext.Consumer>
        {(contextValue) => {
          const { onChangeData, isPlaying, currentUrl, onChangePlay } = contextValue
          return (
            <article
              className={classNames(className, styles.root)}
              onClick={() => onChangeData(podcastFile, title, guest, cover, slug)}
            >
              <figure
                className={classNames(className, styles.preview, {
                  [styles.active]: isPlaying && podcastFile === currentUrl,
                })}
                onClick={onChangePlay}
              >
                <Img fluid={cover.fluid} className={styles.customHeight} />
                <button aria-label="play" className={styles.playButton}>
                  <PlayButton height="56px" width="56px" />
                </button>
                <SocialShare
                  title={title}
                  isWhiteFixed={true}
                  url={`${getBaseUrl()}${locale}/podcast/${slug}`}
                  className={styles.share}
                />
                <p className={styles.audioDuration}>{duration}</p>
              </figure>
              <p className={styles.podcastTitle}>{title}</p>
            </article>
          )
        }}
      </PodcastContext.Consumer>
    )
  }
}

Preview.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  podcastFile: PropTypes.string,
  guest: PropTypes.string,
  coverUrl: PropTypes.string,
  duration: PropTypes.string,
  slug: PropTypes.string,
  locale: PropTypes.string,
}

export default Preview
