import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Intro from './Intro'
import styles from './Hot.module.scss'
import Img from 'gatsby-image'
class Hot extends Component {
  render() {
    const { className, locale, mainInterview, parentSlug } = this.props
    return (
      <section className={classNames(className, styles.root)}>
        <figure className={styles.backgroundImage}>
          <Img sizes={mainInterview.image.fluid} />
        </figure>

        <Intro article={mainInterview} locale={locale} parentSlug={parentSlug} />
      </section>
    )
  }
}

Hot.propTypes = {
  className: PropTypes.string,
  article: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.object,
    podcast: PropTypes.string,
    podcastCover: PropTypes.string,
    podcastGuest: PropTypes.string,
    podcastTitle: PropTypes.string,
    publishDate: PropTypes.string,
    slug: PropTypes.string,
    templateKey: PropTypes.string,
    title: PropTypes.string,
  }),
}

export default Hot
