import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Item from './Item'
import { FormattedMessage } from 'react-intl'
import { Link } from 'gatsby'
import { getLink } from 'src/utils'
import styles from './NewsFeed.module.scss'

const NewsFeed = ({ news, ...props }) => {
  return (
    <div className={styles.wrapper}>
      <section id="news" className={styles.root}>
        {news.length > 0 && <NewsContainer news={news} {...props} />}
      </section>
    </div>
  )
}

const NewsContainer = ({ news, ...props }) => {
  const [mainNews, ...newsList] = news
  const { locale } = props

  return (
    <div className={styles.inner}>
      <NewsLink {...props} />
      <>
        {mainNews.node.title !== null && <FeaturedItem {...mainNews} locale={locale} />}
        <NewsList newsList={newsList} locale={locale} />
      </>
    </div>
  )
}

const FeaturedItem = (props) => (
  <div className={classNames(styles.news, styles.first)}>
    <Item {...props} />
  </div>
)

const NewsList = ({ newsList, locale }) => (
  <div className={classNames(styles.news, styles.second)}>
    <article className={styles.wrap}>
      {newsList.map(({ node: { title, publishdate, slug } }) => (
        <div className={styles.container} key={slug}>
          <Link to={getLink(locale, `news-list/${slug}`)} className={styles.link}>
            <p className={styles.description}>
              {title && title.length >= 100 ? `${title.substring(0, 90)}...` : title}
            </p>
          </Link>
          <div className={styles.days}>
            <span>{publishdate}</span>
          </div>
        </div>
      ))}
    </article>
  </div>
)

const NewsLink = ({ inversed, locale }) => (
  <Link to={getLink(locale, 'news-list')} className={classNames(styles.title, { [styles.inversed]: inversed })}>
    <FormattedMessage id="home.news" />
  </Link>
)

NewsContainer.propTypes = {
  news: PropTypes.array,
}

NewsLink.propTypes = {
  inversed: PropTypes.bool,
  locale: PropTypes.string,
}

NewsFeed.propTypes = {
  news: PropTypes.array,
}

NewsList.propTypes = {
  news: PropTypes.array,
}

export default NewsFeed
