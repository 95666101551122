import React, { Component } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ListenPodcast from 'src/components/ListenPodcast'
import styles from './Intro.module.scss'
import { isOnline, removeListeners, getLink } from 'src/utils'

class Intro extends Component {
  state = { isOnline: true }

  componentDidMount() {
    isOnline(this)
  }

  componentWillUnmount() {
    removeListeners()
  }

  render() {
    const {
      className,
      article: { slug, podcast, templateKey, title, description },
      locale,
      parentSlug,
    } = this.props

    const customPodcast = () => {
      if (podcast[0]) {
        return {
          podcast: { url: podcast[0].podcast.url },
          podcastTitle: podcast[0].podcastTitle,
          podcastGuest: podcast[0].podcastGuest,
          podcastCover: podcast[0].podcastCover,
        }
      } else {
        return {
          podcast: { url: null },
          podcastTitle: null,
          podcastGuest: null,
          podcastCover: null,
        }
      }
    }

    const podcastEl = (
      <ListenPodcast
        intro
        podcast={podcast && customPodcast()}
        parentTemplateKey={templateKey}
        parentSlug={parentSlug}
      />
    )

    return (
      <section className={classNames(className, styles.root)}>
        <div className={styles.inner} style={{ position: 'relative' }}>
          <Link className={styles.link} to={getLink(locale, `interview/${slug}`)}>
            <h1 className={styles.title}>{title && title.length >= 180 ? `${title.substring(0, 180)}...` : title}</h1>
            <h2 className={styles.lead}>
              {description && description.length >= 240 ? `${description.substring(0, 240)}...` : description}
            </h2>
          </Link>
          {podcast && (this.state.isOnline ? podcastEl : <div className={styles.offlineMode}>{podcastEl}</div>)}
        </div>
      </section>
    )
  }
}

Intro.propTypes = {
  className: PropTypes.string,
  article: PropTypes.shape({
    description: PropTypes.string,
    podcast: PropTypes.array,
    podcastCover: PropTypes.string,
    podcastGuest: PropTypes.string,
    podcastTitle: PropTypes.string,
    slug: PropTypes.string,
    templateKey: PropTypes.string,
    title: PropTypes.string,
  }),
}

export default Intro
