import React, { Component } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Item.module.scss'
import { getLink } from 'src/utils'

class Item extends Component {
  render() {
    const {
      className,
      node: { slug, title, adviceCategory },
      locale,
    } = this.props
    return (
      <>
        {title && (
          <article className={classNames(className, styles.root)}>
            <div className={classNames(className, styles.wrap)}>
              <Link to={getLink(locale, `advice/${slug}`)} className={styles.title}>
                <h3>{title && title.length >= 70 ? `${title.substring(0, 70)}...` : title}</h3>
              </Link>
              <div className={styles.days}>
                <Link className={styles.categoryLink} to={getLink(locale, `advice-category/${adviceCategory.slug}`)}>
                  <span>{adviceCategory.title}</span>
                </Link>
              </div>
            </div>
          </article>
        )}
      </>
    )
  }
}

Item.propTypes = {
  className: PropTypes.string,
  slug: PropTypes.string,
  publishdate: PropTypes.string,
  title: PropTypes.string,
}

export default Item
