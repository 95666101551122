import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Swiper from 'react-id-swiper'
import Preview from './Preview'
import styles from './Previews.module.scss'

class Previews extends Component {
  swiper = null

  handleNextClick = () => {
    this.swiper.slideNext()
  }

  handlePrevClick = () => {
    this.swiper.slidePrev()
  }

  handlePreviewClick = (url, title, description, duration) => {
    this.props.onPreviewClick(url, title, description, duration)
  }

  render() {
    const { className, videos, activeVideo, swiperRebuild, locale } = this.props

    const sliderParams = {
      slidesPerView: 3,
      spaceBetween: 20,
      rebuildOnUpdate: swiperRebuild,
      noSwiping: true,
      breakpoints: {
        1200: {
          slidesPerView: 3,
        },
        860: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
      },
    }

    return (
      <div className={classNames(className, styles.root, 'Video')}>
        <button onClick={this.handleNextClick} className={styles.next} />
        <button onClick={this.handlePrevClick} className={styles.prev} />
        <Swiper {...sliderParams} ref={(node) => (node ? (this.swiper = node.swiper) : null)}>
          {videos.map(({ node }) => {
            return (
              <div key={node.videoId}>
                <Preview {...node} onPreviewClick={this.handlePreviewClick} activeVideo={activeVideo} locale={locale} />
              </div>
            )
          })}
        </Swiper>
      </div>
    )
  }
}

Preview.propTypes = {
  className: PropTypes.string,
  videos: PropTypes.array.isRequired,
  swiperRebuild: PropTypes.bool,
  locale: PropTypes.string,
}

export default Previews
