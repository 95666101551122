import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from 'src/components/Layout'
import Hot from 'src/components/Hot'
import NewsFeed from 'src/components/NewsFeed'
import Footer from 'src/components/Footer'
import Home from 'src/components/Home'
// ***Temporarily disabled ***
// import Jobs from 'src/components/Jobs'
import Factory from 'src/components/Factory'
import AdvicesWidget from 'src/components/AdvicesWidget'
import withLayout from 'src/components/withLayout'
// ***Temporarily disabled ***
// import Partners from 'src/components/Partners'
import Header from 'src/components/Header'
import SubscribeAdditional from 'src/components/Subscribe/SubscribeAdditional'
import PageHelmet from 'src/components/PageHelmet'
import StoriesFeed from 'src/components/StoriesFeed'
import { injectIntl } from 'react-intl'

class HomeTemplate extends Component {
  render() {
    const {
      data: {
        news: { edges: newsEdges },
        mainInterview,
        interviews: { edges: interviewsEdges },
        // ***Temporarily disabled ***
        // jobTags: { edges: jobTags },
        offers: { edges: offers },
        advices: { edges: advicesEdges },
        subscribe,
        // ***Temporarily disabled ***
        // partners,
        promotion,
        factory,
        factoryRecord,
        stories,
        siteData: { edges: siteDataEdges },
        podcasts: { edges: podcasts },
        video: { edges: video },
        channels: { edges: channels },
      },
      locale,
      changeLocale,
      intl,

      ...props
    } = this.props

    const prepareNews = (news) => news.filter(({ node }) => node.slug !== '_')
    const news = prepareNews(newsEdges)
    const siteData = siteDataEdges[0].node

    const parentSlugPodcastInHot = podcasts.filter(({ node }) => {
      if (mainInterview.podcast[0]) {
        return node.podcast.url === mainInterview.podcast[0].podcast.url
      }
      return null
    })

    const duotonMainImage = `${
      mainInterview.image.url
    }?w=600&markalign=center%2Cmiddle&blend64=aHR0cHM6Ly9zYW5kYm94LXVwbG9hZHMuaW1naXgubmV0L3UvMTU1ODYwOTE5NS1hOTUwNGIwOGZlYzZiZmRlZDRkOGJmODY3M2UwMTU0ND93PTYwMA&bm=multiply&mark64=aHR0cHM6Ly9zYW5kYm94LXVwbG9hZHMuaW1naXgubmV0L3UvMTU1ODYwOTcyNy05N2ZjMDdmMzllMjNiNTMzNTE1ODFhNDVkNzNhYzEyMz93PTYwMA`

    return (
      <Layout locale={locale}>
        <main>
          <PageHelmet
            title={siteData.title}
            description={siteData.description}
            locale={locale}
            image={duotonMainImage}
            defer={false}
          />

          <Header locale={locale} {...props} fixed />
          <Hot
            locale={locale}
            changeLocale={() => changeLocale()}
            mainInterview={mainInterview}
            parentSlug={parentSlugPodcastInHot[0] && parentSlugPodcastInHot[0].node.slug}
          />
          <NewsFeed news={news} locale={locale} />
          <StoriesFeed stories={stories} locale={locale} inversed />
          {/* ***Temporarily disabled *** */}
          {/* <Partners partners={partners} /> */}
          <Home
            onChangeData={this.props.onChangeData}
            locale={locale}
            news={news}
            interviews={interviewsEdges}
            channels={channels}
            video={video}
            ad={promotion}
          />
          {/* ***Temporarily disabled *** */}
          {/* <Jobs jobTags={jobTags} offers={offers} locale={locale} /> */}
          <AdvicesWidget news={news} advices={advicesEdges} locale={locale} />
          <Factory {...{ factory, factoryRecord, locale }} />
          <SubscribeAdditional locale={locale} subscribe={subscribe} />
        </main>
        <Footer locale={locale} />
      </Layout>
    )
  }
}

export const query = graphql`
  query HomePage($locale: String!) {
    interviews: allDatoCmsInterview(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          title
          description
          author
          photographer
          publishDate
          ismain
          image {
            url
            fluid(maxWidth: 1240, imgixParams: { fm: "jpg", auto: "compress, format" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          podcast {
            podcastTitle
            podcastGuest
            podcastCover {
              id
              fluid(maxWidth: 400, imgixParams: { fm: "jpg", auto: "compress, format" }) {
                ...GatsbyDatoCmsFluid
              }
            }
            duration
            podcast {
              url
            }
          }
          summary {
            title
            description
          }
          bodyNode {
            childMarkdownRemark {
              html
            }
          }
          slug
        }
      }
    }
    mainInterview: datoCmsInterview(locale: { eq: $locale }, ismain: { eq: true }) {
      publishDate(formatString: "DD.MM.YYYY")
      slug
      title
      description
      ismain
      image {
        id
        fluid(maxWidth: 1240, imgixParams: { fm: "jpg", auto: "compress, format" }) {
          ...GatsbyDatoCmsFluid
        }
        url
      }
      podcast {
        podcastTitle
        podcastGuest
        podcastCover {
          id
          fluid(maxWidth: 400, imgixParams: { fm: "jpg", auto: "compress, format" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        duration
        podcast {
          url
        }
      }
      author
    }

    news: allDatoCmsNews(
      filter: { ismain: { eq: true }, locale: { eq: $locale } }
      sort: { fields: [publishdate], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          publishdate(formatString: "DD.MM.YYYY")
          slug
          title
          description
          ismain
          image {
            id
            fluid(maxWidth: 1240, imgixParams: { fm: "jpg", auto: "compress, format" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          author
        }
      }
    }

    jobTags: allDatoCmsJobTag(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          title
          locale
          id
        }
      }
    }
    offers: allDatoCmsJob(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          locale
          id
          title
          city
          description
          companyName
          companyLogo {
            url
          }
          tag {
            id
            title
          }
          slug
        }
      }
    }
    advices: allDatoCmsAdvice(
      filter: { locale: { eq: $locale } }
      sort: { fields: [meta___firstPublishedAt], order: DESC }
      limit: 4
    ) {
      edges {
        node {
          title
          slug
          adviceCategory {
            title
            slug
          }
          meta {
            firstPublishedAt(formatString: "DD.MM.YYYY")
          }
        }
      }
    }
    subscribe: file(relativePath: { eq: "subscribe-illustration.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    promotion: allDatoCmsPromotion(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          autoplayDelay
          slides {
            id
            title
            image {
              url
            }
            link
          }
        }
      }
    }
    # ***Temporarily disabled ***
    # partners: allDatoCmsPartner(
    #   filter: { locale: { eq: $locale } }
    #   sort: { fields: [meta___updatedAt], order: DESC }
    # ) {
    #   edges {
    #     node {
    #       id
    #       link
    #       title
    #       description
    #       image {
    #         id
    #         fluid(maxWidth: 400, imgixParams: { fm: "jpg", auto: "compress, format" }) {
    #           ...GatsbyDatoCmsFluid
    #         }
    #       }
    #     }
    #   }
    # }
    factory: allDatoCmsFactory(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          id
          logotypeUrl
          logotype {
            id
            url
            alt
          }
          formName
          buttonOpenFormText
          buttonSendFormText
        }
      }
    }

    factoryRecord: allDatoCmsFactoryRecord(filter: { locale: { eq: $locale } }, limit: 3) {
      edges {
        node {
          title
          ismain
          image {
            id
            alt
            url
          }
          podcast {
            podcastTitle
            podcastGuest
            podcastCover {
              id
              fluid(maxWidth: 400, imgixParams: { fm: "jpg", auto: "compress, format" }) {
                ...GatsbyDatoCmsFluid
              }
            }
            duration
            podcast {
              url
            }
          }
          summary {
            title
            description
          }
          description
          author
          photographer
          publishDate
          body
          slug
        }
      }
    }

    siteData: allDatoCmsSiteInformation(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }

    stories: allDatoCmsStory(filter: { locale: { eq: $locale }, title: { ne: null } }, limit: 3) {
      edges {
        node {
          id
          title
          description
          publishDate(formatString: "DD.MM")
          slug
          darkSubstrateForBackground
          image {
            id
            alt
            url
            fluid(maxWidth: 1400, imgixParams: { fm: "jpg", auto: "compress,format" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          storyCards {
            title
            body
            backgroundImage {
              id
              url
              alt
            }
          }
        }
      }
    }
    podcasts: allDatoCmsPodcast(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          slug
          podcast {
            url
          }
        }
      }
    }

    video: allDatoCmsVideo2(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          channel {
            title
          }
          title
          videoId
          description
          duration
          publishDate
          slug
        }
      }
    }

    channels: allDatoCmsVideoChannel(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          title
        }
      }
    }
  }
`

const customProps = {
  localeKey: 'home',
}

export default withLayout(customProps)(injectIntl(HomeTemplate))
