import React, { Component } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { getLink } from 'src/utils'
import styles from './Item.module.scss'

class Item extends Component {
  render() {
    const {
      node: { slug, publishdate, title, image },
      locale,
    } = this.props

    return (
      <article className={styles.root}>
        <div>
          <Img fluid={image.fluid} alt="" className={styles.image} />
        </div>
        <div className={styles.wrap}>
          <Link to={getLink(locale, `news-list/${slug}`)} className={styles.title}>
            <h3>{title && title.length >= 100 ? `${title.substring(0, 90)}...` : title}</h3>
          </Link>
          <div className={styles.days}>
            <span>{publishdate}</span>
          </div>
        </div>
      </article>
    )
  }
}

Item.displayName = 'NewsFeed.Item'

Item.propTypes = {
  className: PropTypes.string,
  slug: PropTypes.string,
  publishdate: PropTypes.string,
  title: PropTypes.string,
}

export default Item
