import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Player from './Player'
import Previews from './Previews'
import { Link } from 'gatsby'
import { getLink } from 'src/utils'
import { FormattedMessage } from 'react-intl'

import styles from './Video.module.scss'

class Video extends Component {
  state = {
    activeChannel: this.props.channels[0],
    activeVideo: null,
    activeVideoTitle: '',
    activeVideoDescription: '',
    activeVideoDuration: '',
    swiperRebuild: false,
  }

  handlePreviewClick = (url, title, description, duration) => {
    this.setState({
      activeVideo: url,
      activeVideoTitle: title,
      activeVideoDescription: description,
      activeVideoDuration: duration,
      swiperRebuild: false,
    })
  }

  render() {
    const { className, isAdminDashboard, locale, video } = this.props
    const videos = video.filter(({ node }) => node.title)

    return (
      <div className={classNames(className, styles.root)}>
        <section className={styles.inner}>
          <header className={styles.header}>
            <Link to={getLink(locale, 'videos')}>
              <h1 className={styles.header}>
                <FormattedMessage id="home.video" />
              </h1>
            </Link>
          </header>
          <Player
            {...videos[0]}
            activeVideo={this.state.activeVideo}
            activeVideoTitle={this.state.activeVideoTitle}
            activeVideoDescription={this.state.activeVideoDescription}
            activeVideoDuration={this.state.activeVideoDuration}
            isAdminDashboard={isAdminDashboard}
          />
          <Previews
            videos={videos}
            activeVideo={this.state.activeVideo}
            onPreviewClick={this.handlePreviewClick}
            swiperRebuild={this.state.swiperRebuild}
            locale={locale}
          />
        </section>
      </div>
    )
  }
}

Video.propTypes = {
  className: PropTypes.string,
  locale: PropTypes.string,
  channels: PropTypes.array,
  video: PropTypes.array,
}

export default Video
