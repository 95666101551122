import React, { Component } from 'react'
import noConnection from 'src/img/no-connection.svg'
import { FormattedMessage } from 'react-intl'

import styles from './OfflineContainer.module.scss'

class OfflineContainer extends Component {
  render() {
    return (
      <div className={styles.root}>
        <img className={styles.noConnection} src={noConnection} alt="" />
        <p>
          {' '}
          <FormattedMessage id="home.offlineContent" />
        </p>
      </div>
    )
  }
}

export default OfflineContainer
