import React, { Component } from 'react'
import classNames from 'classnames'
import Preview from './Preview'
import PropTypes from 'prop-types'
import styles from './Previews.module.scss'

class Previews extends Component {
  handlePreviewClick = (url, title, description) => {
    this.props.onPreviewClick(url, title, description)
  }

  prepareAudios = (audios) => {
    return audios.filter(({ node: { title } }) => title !== null)
  }

  render() {
    const {
      className,
      url,
      activeAudio,
      locale,
      podcasts: { edges: audios },
    } = this.props

    return (
      <div
        className={classNames(className, styles.root, {
          [styles.active]: activeAudio === url,
        })}
      >
        {this.prepareAudios(audios).map((item, index) => {
          if (index < 2) {
            return (
              <div className={styles.wrap} key={item.node.title}>
                <Preview {...item} onPreviewClick={this.handlePreviewClick} activeAudio={activeAudio} locale={locale} />
              </div>
            )
          } else {
            return null
          }
        })}
      </div>
    )
  }
}
Previews.propTypes = {
  className: PropTypes.string,
  audios: PropTypes.array,
  url: PropTypes.string,
  activeAudio: PropTypes.string,
  locale: PropTypes.string.isRequired,
}

export default Previews
