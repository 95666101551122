import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'gatsby'
import { getLink } from 'src/utils'
import styles from './Factory.module.scss'
import submit from 'src/img/submit.svg'

class Factory extends Component {
  state = {
    value: {},
    isModalVisible: false,
    isSubmitted: false,
  }

  render() {
    const {
      intl,
      inversed,
      locale,
      factory: { edges: factoryEdges },
      // factoryRecord: { edges: factoryRecordEdges },
    } = this.props

    const closeModal = (e) => {
      if (e.target.className.search('formModalWrap') !== -1) {
        this.setState(
          {
            isModalVisible: !this.state.isModalVisible,
          },
          () => {
            if (typeof window !== 'undefined') {
              window.document.body.style.overflow = 'auto'
            }
          },
        )
      }
    }

    const openModal = (e) => {
      e.preventDefault()
      this.setState(
        {
          isModalVisible: !this.state.isModalVisible,
        },
        () => {
          if (typeof window !== 'undefined' && this.state.isModalVisible) {
            window.document.body.style.overflow = 'hidden'
          }
          if (typeof window !== 'undefined' && !this.state.isModalVisible) {
            window.document.body.style.overflow = 'auto'
          }
        },
      )
    }

    const encode = (data) => {
      return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
    }

    const handleChangeInput = (e) => {
      this.setState({
        value: {
          ...this.state.value,
          [e.target.name]: e.target.value,
        },
      })
    }

    const onSubmit = (e) => {
      e.preventDefault()
      const form = e.target
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...this.state.value,
        }),
      })
        .then(() =>
          this.setState({
            isSubmitted: true,
          }),
        )
        .catch((error) => console.log(error))
    }

    return (
      <div className={classNames(styles.root, this.state.isModalVisible && styles.isModalVisible)}>
        <div className={styles.wrap}>
          <div className={styles.inner}>
            <Link to={getLink(locale, '/')} className={classNames(styles.title, { [styles.inversed]: inversed })}>
              <FormattedMessage id="home.factory" />
            </Link>
            {factoryEdges.map(
              ({ node: { id, logotype, logotypeUrl, formName, buttonSendFormText, buttonOpenFormText } }) => (
                <Fragment key={id}>
                  <div className={styles.factoryBlock}>
                    <div className={styles.factoryForm}>
                      {logotype && (
                        <a
                          rel="noopener noreferrer nofollow"
                          href={logotypeUrl}
                          target="_blank"
                          className={styles.partner}
                        >
                          <img
                            className={styles.grandad}
                            src={logotype && logotype.url}
                            alt={logotype && logotype.alt}
                          />
                        </a>
                      )}
                      {/* TODO: вернуть когда будет первая утверждённая статья */}
                      {/* <button onClick={openModal} className={styles.openModal}>
                        {buttonOpenFormText}
                      </button> */}
                      {this.state.isModalVisible && (
                        <div className={styles.formModalWrap} onClick={closeModal}>
                          <div className={styles.formInner}>
                            <form
                              className={styles.formModal}
                              style={!this.state.isSubmitted ? { display: 'flex' } : { display: 'none' }}
                              name={formName}
                              method="post"
                              data-netlify="true"
                              data-netlify-honeypot="bot-field"
                              onSubmit={onSubmit}
                            >
                              <button className={styles.buttonClose} onClick={openModal} />
                              <input type="hidden" name="bot-field" />
                              <input type="hidden" name="form-name" value="factory" />
                              <input
                                onChange={(e) => handleChangeInput(e)}
                                required
                                type="text"
                                name="name"
                                placeholder={intl.formatMessage({ id: 'home.name' })}
                                className={styles.input}
                              />
                              <input
                                onChange={(e) => handleChangeInput(e)}
                                type="text"
                                name="phone"
                                placeholder={intl.formatMessage({ id: 'home.phone' })}
                                className={styles.input}
                              />
                              <textarea
                                onChange={(e) => handleChangeInput(e)}
                                type="text"
                                name="message"
                                placeholder={intl.formatMessage({ id: 'home.message' })}
                                className={styles.textarea}
                                rows="5"
                              />
                              <button className={styles.openModal} type="submit">
                                {buttonSendFormText}
                              </button>
                            </form>
                            <div
                              className={styles.successMessage}
                              style={this.state.isSubmitted ? { display: 'block' } : { display: 'none' }}
                            >
                              <h2>{intl.formatMessage({ id: 'home.adviceFormSuccess' })}</h2>
                              <img src={submit} alt="check" />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.factoryStories}>
                    {/* TODO: удалить когда будет первая утверждённая статья */}
                    <button onClick={openModal} className={styles.openModal}>
                      {buttonOpenFormText}
                    </button>
                    {/* TODO: вернуть когда будет первая утверждённая статья */}
                    {/* {factoryRecordEdges.map(({ node: { title, slug, publishDate } }) => {
                      if (slug && slug !== '_') {
                        return (
                          <article className={styles.article}>
                            <div className={styles.articleCard}>
                              <Link to={getLink(locale, `factory/${slug}`)} className={styles.subtitle}>
                                <h3>{title && title.length >= 100 ? `${title.substring(0, 90)}...` : title}</h3>
                              </Link>
                              <div className={styles.days}>
                                <span>{publishDate}</span>
                              </div>
                            </div>
                          </article>
                        )
                      }
                    })} */}
                  </div>
                </Fragment>
              ),
            )}
          </div>
        </div>
      </div>
    )
  }
}

Factory.propTypes = {
  className: PropTypes.string,
  locale: PropTypes.string.isRequired,
}

export default injectIntl(Factory)
