import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import PlayButton from 'src/components/svg/PlayButton'
import styles from './Preview.module.scss'

class Preview extends Component {
  hanldeClick = (url, title, description, duration) => () => {
    this.props.onPreviewClick(url, title, description, duration)
  }

  render() {
    const { className, title, videoId, description, duration, activeVideo } = this.props
    return (
      <article
        className={classNames(className, styles.root, {
          [styles.active]: activeVideo === videoId,
        })}
        onClick={this.hanldeClick(videoId, title, description, duration)}
      >
        <figure className={styles.preview}>
          <img src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`} alt={title} />
          <button className={styles.playButton} aria-label="play">
            <PlayButton height="56px" width="56px" />
          </button>
          <p className={styles.duration}>{duration}</p>
        </figure>
        <p className={styles.title}>{title}</p>
      </article>
    )
  }
}

Preview.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  duration: PropTypes.string,
}

export default Preview
