import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Item from './Item'
import { FormattedMessage } from 'react-intl'
import { Link } from 'gatsby'
import { getLink } from 'src/utils'
import styles from './AdvicesWidget.module.scss'

class AdvicesWidget extends Component {
  render() {
    const { className, style, inversed, locale, advices } = this.props

    return (
      <div className={styles.wrapper}>
        <section className={classNames(className, styles.root)} style={style}>
          {advices.length > 0 && (
            <div className={styles.inner}>
              <Link
                to={getLink(locale, 'advices')}
                className={classNames(styles.title, { [styles.inversed]: inversed })}
              >
                <FormattedMessage id="home.advices" />
              </Link>

              {advices[0].node.title !== null ? (
                <>
                  <div className={classNames(styles.news, styles.first)}>
                    <Item {...advices[0]} locale={locale} />
                  </div>
                  <div className={classNames(styles.news, styles.second)}>
                    {advices.slice(1, 3).map((item) => {
                      return <Item locale={locale} key={item.node.slug} {...item} />
                    })}
                  </div>
                </>
              ) : (
                <>
                  <div className={classNames(styles.news, styles.first)}>
                    <Item {...advices[1]} locale={locale} />
                  </div>
                  <div className={classNames(styles.news, styles.second)}>
                    {advices.slice(2, 4).map((item) => {
                      return <Item locale={locale} key={item.node.slug} {...item} />
                    })}
                  </div>
                </>
              )}
            </div>
          )}
        </section>
      </div>
    )
  }
}

AdvicesWidget.propTypes = {
  className: PropTypes.string,
  advices: PropTypes.array,
  style: PropTypes.string,
  inversed: PropTypes.bool,
  locale: PropTypes.string,
}

export default AdvicesWidget
