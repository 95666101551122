import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Item from './Item'
import { FormattedMessage } from 'react-intl'
import { Link } from 'gatsby'
import Swiper from 'react-id-swiper'
import { getLink } from 'src/utils'
import styles from './StoriesFeed.module.scss'

class StoriesFeed extends Component {
  state = {
    width: 0,
  }

  componentDidMount() {
    this.updateWindowDimensions()

    typeof window !== 'undefined' && window.addEventListener('resize', this.updateWindowDimensions.bind(this))
  }

  componentWillUnmount() {
    typeof window !== 'undefined' && window.removeEventListener('resize', this.updateWindowDimensions.bind(this))
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth })
  }

  render() {
    const sliderParams = {
      rebuildOnUpdate: true,
      shouldSwiperUpdate: true,
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 50,
      preventClicks: false,
      preventClicksPropagation: false,
      speed: 500,
      loop: false,
      autoplay: true,
      breakpoints: {
        560: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
      },
    }

    const {
      className,
      stories: { edges },
      style,
      inversed,
      locale,
    } = this.props

    const stories = [...edges]

    return (
      <div className={styles.wrapper}>
        <section id="stories" className={classNames(className, styles.root)} style={style}>
          {stories.length > 0 && (
            <div className={styles.inner}>
              <Link
                to={getLink(locale, 'stories-list')}
                className={classNames(styles.title, { [styles.inversed]: inversed })}
              >
                <FormattedMessage id="home.stories" />
              </Link>
              <div className={styles.stories}>
                {this.state.width >= 930 &&
                  stories.map((item) => {
                    return <Item locale={locale} key={item.node.slug} {...item} />
                  })}

                {this.state.width < 930 && (
                  <Swiper {...sliderParams}>
                    {stories.map((item) => {
                      return <Item locale={locale} key={item.node.slug} {...item} />
                    })}
                  </Swiper>
                )}
              </div>
            </div>
          )}
        </section>
      </div>
    )
  }
}

StoriesFeed.propTypes = {
  className: PropTypes.string,
  stories: PropTypes.object,
  style: PropTypes.string,
  inversed: PropTypes.bool,
  locale: PropTypes.string,
}

export default StoriesFeed
