import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import classNames from 'classnames'
import { compareDesc } from 'date-fns'
// import Video from 'src/components/Video'
import Video from 'src/components/Video'
import Audio from 'src/components/Audio'
import Item from './Item'
import ItemWithoutPreview from './ItemWithoutPreview'
import styles from './Home.module.scss'
import Ad from '../Ad'
import OfflineContainer from 'src/components/OfflineContainer'
import { isOnline, removeListeners } from 'src/utils'

const articlesNumber = 6

class Home extends Component {
  state = {
    isOnline: true,
  }

  componentDidMount() {
    isOnline(this)
  }

  componentWillUnmount() {
    removeListeners()
  }

  render() {
    const { className, interviews, podcasts, news, ad, locale, video, channels } = this.props

    const filteredInterviews = interviews.filter((interview) => interview.node.slug !== '_')

    const articles = [...filteredInterviews, ...news].sort((articlePrev, articleNext) =>
      compareDesc(articlePrev.publishDate, articleNext.publishDate),
    )
    const slicedArticles = articles.slice(0, articlesNumber)
    return (
      <>
        <section className={classNames(className, styles.root)}>
          <div id="interviews" className={styles.interviews}>
            <div className={styles.interviewsWrapper}>
              <div className={styles.interviewsInner}>
                {articles
                  .slice(0, 2)
                  .map((item) => (
                    <ItemWithoutPreview
                      layoutFlag={true}
                      key={item.node.slug}
                      {...item}
                      locale={locale}
                      isFullWidth
                      podcasts={podcasts.edges}
                    />
                  ))}
              </div>
            </div>

            <div className={styles.ad}>
              <Ad isOnHomePage locale={locale} ad={ad} />
            </div>
          </div>

          {channels && (
            <div id="video" className={styles.videoWrapper}>
              {!this.state.isOnline && <OfflineContainer locale={locale} />}
              <Video video={video} channels={channels} locale={locale} />
            </div>
          )}

          {podcasts &&
            locale !== 'en' && (
              <div id="podcasts" className={styles.podcastsWrapper}>
                {!this.state.isOnline && <OfflineContainer locale={locale} />}

                <Audio podcasts={podcasts} onChangeData={this.props.onChangeData} locale={locale} />
              </div>
            )}

          <div className={styles.articlesWrapper}>
            <div className={styles.articles}>
              {slicedArticles.slice(2, 5).map((item, index) => {
                const lastItem = slicedArticles.slice(9).length
                return index === lastItem ? (
                  <Item locale={locale} key={item.node.slug} {...item} podcasts={podcasts.edges} />
                ) : (
                  <ItemWithoutPreview key={item.node.slug} {...item} locale={locale} podcasts={podcasts.edges} />
                )
              })}
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        podcasts: allDatoCmsPodcast {
          edges {
            node {
              title
              duration
              publishDate
              guest
              cover {
                url
                id
                fluid(maxWidth: 1400, imgixParams: { fm: "jpg", auto: "compress,format" }) {
                  ...GatsbyDatoCmsFluid
                }
              }
              podcast {
                url
              }
              slug
            }
          }
        }
        interviews: allDatoCmsInterview {
          edges {
            node {
              title
              description
              author
              photographer
              publishDate
              podcast {
                podcastTitle
                podcastGuest
                podcastCover {
                  id
                  fluid(maxWidth: 400, imgixParams: { fm: "jpg", auto: "compress, format" }) {
                    ...GatsbyDatoCmsFluid
                  }
                }
                duration
                podcast {
                  url
                }
              }
              image {
                url
                fluid(maxWidth: 1240, imgixParams: { fm: "jpg", auto: "compress, format" }) {
                  ...GatsbyDatoCmsFluid
                }
              }
              summary {
                title
                description
              }
              # bodyNode {
              #   childMarkdownRemark {
              #     html
              #   }
              # }
              slug
            }
          }
        }
      }
    `}
    render={({ interviews: { edges }, podcasts }) => {
      return <Home interviews={edges} podcasts={podcasts} {...props} />
    }}
  />
)
