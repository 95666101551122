import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import YouTube from 'react-youtube'
import styles from './Player.module.scss'

class Player extends Component {
  onVideoReady(event) {
    event.target.pauseVideo()
  }

  render() {
    const {
      className,
      node: { title, duration, videoId },
      activeVideo,
      activeVideoTitle,
      activeVideoDuration,
    } = this.props
    const options = {
      playerVars: {
        autoplay: 0,
        showinfo: 0,
      },
    }

    return (
      <div className={classNames(className, styles.root)}>
        <h2 className={styles.title}>
          <span className={styles.isTitle}>{activeVideo === null ? title : activeVideoTitle}</span>
          <p className={styles.duration}>{activeVideo === null ? duration : activeVideoDuration}</p>
        </h2>
        <div className={styles.videoWrapper}>
          <div className={styles.video}>
            <YouTube
              videoId={activeVideo === null ? videoId : activeVideo}
              onReady={this.onVideoReady}
              opts={options}
            />
          </div>
        </div>
      </div>
    )
  }
}

Player.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  duration: PropTypes.string,
  videoId: PropTypes.string,
  activeVideo: PropTypes.string,
  activeVideoTitle: PropTypes.string,
  activeVideoDuration: PropTypes.string,
}

export default Player
