import React, { Component } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SocialShare from 'src/components/SocialShare'
import ListenPodcast from 'src/components/ListenPodcast'
import Img from 'gatsby-image'
import styles from './Item.module.scss'
import { getBaseUrl } from 'src/utils'
import { isOnline, removeListeners, getLink } from 'src/utils'

class Item extends Component {
  state = { isOnline: true }

  componentDidMount() {
    isOnline(this)
  }

  componentWillUnmount() {
    removeListeners()
  }

  render() {
    const {
      className,
      node: { title, description, image, slug, podcast },
      locale,
      podcasts,
    } = this.props

    const podcastSlug =
      podcasts && podcast[0] && podcasts.filter(({ node }) => node.podcast.url === podcast[0].podcast.url)

    const podcastEl = (
      <ListenPodcast
        reverse
        style={{ position: 'unset' }}
        podcast={podcast && podcast.length > 0 && podcast[0]}
        parentSlug={podcastSlug && podcastSlug.length > 0 && podcastSlug[0].node.slug}
      />
    )

    return (
      <div className={styles.link}>
        <article className={classNames(className, styles.root)}>
          <Img fluid={image.fluid} className={styles.cover} />
          <div className={styles.inner}>
            <Link className={styles.title} to={getLink(locale, `interview/${slug}`)}>
              {title && title.length >= 140 ? title.substring(0, 140) + '...' : title}
            </Link>
            <h3>{description && description.length >= 160 ? description.substring(0, 160) + '...' : description}</h3>
            <div style={{ display: 'flex', alignItems: 'space-between' }} className={styles.tags}>
              {podcast &&
                podcast.length > 0 &&
                podcast[0] &&
                (this.state.isOnline ? podcastEl : <div className={styles.offlineMode}>{podcastEl}</div>)}

              {this.state.isOnline ? (
                <SocialShare title={title} url={`${getBaseUrl()}${locale}/interview/${slug}`} />
              ) : (
                <div className={styles.offlineMode}>
                  <SocialShare title={title} url={`${getBaseUrl()}${locale}/interview/${slug}`} />
                </div>
              )}
            </div>
          </div>
        </article>
      </div>
    )
  }
}

Item.displayName = 'Home.Item'

Item.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  slug: PropTypes.string,
}

export default Item
