import React, { Component } from 'react'
import classNames from 'classnames'
import Previews from './Previews'
import { Link } from 'gatsby'
import { getLink } from 'src/utils'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import styles from './Audio.module.scss'

class Audio extends Component {
  state = {
    podcasts: this.props.podcasts,
    activeAudio: null,
    activeAudioTitle: '',
    activeAudioDescription: '',
    swiperRebuild: false,
  }

  render() {
    const { className, locale, podcasts } = this.props

    return (
      <div className={classNames(className, styles.root)}>
        <section className={styles.inner}>
          <header className={styles.header}>
            <Link to={getLink(locale, 'podcasts')}>
              <h1 className={styles.header}>
                <FormattedMessage id="home.audio" />
              </h1>
            </Link>
          </header>
          <Previews
            podcasts={podcasts}
            activeAudio={this.state.activeAudio}
            onChangeData={this.props.onChangeData}
            locale={locale}
          />
        </section>
      </div>
    )
  }
}

Audio.propTypes = {
  className: PropTypes.string,
  locale: PropTypes.string.isRequired,
}

export default Audio
